$micro: 320px;
$small: 480px;
$medium: 667px;
$vtab: 768px;
$htab: 1024px;
$large: 1366px;

header
{
    background: #f9f9f9;
    background-size: cover;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 99999999999999999999;

    @media screen and (max-width: $medium)
    {
        height: auto;
    }

    >.row
    {
        width: 100%;

        .column
        {
            display: flex;

            @media screen and (max-width: $medium)
            {
                width: auto;
            }

            &:nth-child(1),
            &:nth-child(2)
            {
                align-items: center;

                @media screen and (max-width: $medium)
                {
                    text-align: center;
                }
            }

            &:nth-child(3),
            &:nth-child(4)
            {
                align-items: flex-start;
            }
        }
    }
}

button.navbar-toggler 
{
    @media screen and (max-width: $medium)
    {
        position: fixed;
        top: 10px;
        right: 10px;
    }
}

div.navbar-collapse 
{
    @media screen and (max-width: $medium)
    {
        background-color: #fff;
        position: fixed;
        top: 68px;
        width: 100%;
        left: 0;
    }
}

.logo
{
    width: 75px;
    margin-left: 7px;

    @media screen and (max-width: $medium)
    {
        position: relative;
        height: auto;
        margin: 0;
        width: 60px;
    }

    @media screen and (min-width: $vtab) and (max-width: $htab)
    {
        height: auto;
        width: 70px;
    }
}

.text-decoration-none
{
    text-decoration: none;
}

.headerH1
{
    font-size: 32px;
    color: #2ec8a6;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 0;

    @media screen and (max-width: $htab)
    {
        font-size: 30px;
    }

    @media screen and (max-width: $medium)
    {
        margin: 0 auto;
        font-size: 22px;
    }
}

.admin-headerH1
{
    font-size: 1.60rem;
    color: #2ec8a6;
    font-weight: 700;
    margin-top: 12px;

    a
    {
        color: inherit;
        text-decoration: none;
    }
}

.tagline
{
    font-size: 20px;
    color: #2ec8a6;

    @media screen and (max-width: $htab)
    {
        font-size: 17px;
    }

    @media screen and (max-width: $medium)
    {
        font-size: 13px;
    }
}

.navigation-bar
{
    position: relative;
    background-color: "#ffffff";
    padding: 2px;
    min-height: "74px";
    box-shadow: "rgba(0, 0, 0, 0.15) 2px 5px 1rem 4px";
    font-weight: 700;
    font-size: 1rem;
}

a.menu-link
{
    border: 2px solid transparent;
    border-radius: 18px;
    font-weight: 400 !important;
    margin: 5px 4px;
    text-transform: uppercase;
    font-size: 14px !important;
    padding: 4px 7px !important;
    color: #0066a9;

    &:hover 
    {
        border: 2px solid #2ec8a6 !important;
        color: #0066a9 !important;
    }
}

#navbarScroll 
{
    a
    {
        font-weight: 600;
        color: #0066a9;

        &:hover 
        {
            color: #0066a9 !important;
            background-color: #ffffff !important;
        }
    }
    
}

.activeLink 
{
//   border: 2px solid #2ec8a6 !important;
  color: #0066a9 !important;
  border-radius: 47px;

    &:active 
    {
        border: 2px solid #2ec8a6;
    }
}

.linksPadding 
{
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 28px;
}

span.phone,
span.email
{   
    display: flex;
    margin-top: 34px;
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $medium)
    {
        font-size: 14px;
    }
}

.login-options
{
    display: flex;
    align-items: start;

    .login-option-link
    {
        >a
        {
            color: #22a98b;
            padding: 0;
            font-size: 24px;
        }
    }

    svg
    {
        padding: 1px 2px;
        margin-right: 0;
        color: #22a98b;
        margin-top: 11px;
    }
}