$micro: 320px;
$small: 480px;
$medium: 667px;
$vtab: 768px;
$htab: 1024px;
$large: 1366px;

.more-links
{
    .navbar
    {
        @media screen and (max-width: $medium)
        {
            overflow: scroll;
        }
    }
}


.menu-lists 
{
    position: relative;
    height: 66px;
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 0;
    z-index: 0;
    width: 100%;
    padding: 0;

    @media screen and (max-width: $medium)
    {
        flex-wrap: wrap;
        height: auto;
    }

    li
    {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;

        @media screen and (max-width: $medium)
        {
            width: 100%;
            padding: 5px 0;
        }

        a
        {
            padding: 10px 25px;
            color: #fff;
            background-color: #008b8b;
            border-radius: 5px;
            font-weight: 400;
            font-size: 25px;
            text-align: center;

            @media screen and (max-width: $medium)
            {
                padding: 5px 12px;
                font-size: 18px;
            }
        }
    }
    
}