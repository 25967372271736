$micro: 320px;
$small: 480px;
$medium: 667px;

.DashboardHeader {
    display: flex;
    justify-content: center;
}

.top-header
{
    box-shadow: rgba(0, 0, 0, 0.2) -1px -20px 20px 0px, rgba(0, 0, 0, 0.19) 1px -2px 20px 0px;
    z-index: 1;
    position: relative;
    padding: 0 0 10px;
}

.logo-container {
    @media screen and (max-width: $medium) //480
    {
        display: flex;
        justify-content: center;
    }
}